import { useSelector, useDispatch } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { useEffect, useState } from 'react'
import { AuthDialogSpaError, ErrorCode } from '@utils/errors'
import { getClient, getSession, setConsentId } from '@actions/client/clientActions'
import { handleCountryAndProviders, setIsLoading } from '@actions/general/generalActions'
import { getSessionId } from '@utils/helpers'
import { getCountries } from '@actions/countries/countriesActions'
import { sendFailedToInitialize, sendInitializedAnalytics } from '@actions/analytics'
import { getProviders } from '@actions/providers/providersActions'
import { showAuthorisationBanner } from '@utils/authorisation'
import { errorMapper, ErrorUI } from '@components/ErrorBoundary/errors'
import { PagePath } from '@models/IPage'
import { useAbTests, Status as AbTestsStatus } from '@contexts/ab-tests'

const useApp = () => {
  const [error, setError] = useState<ErrorUI | null>(null)
  const isDataLoading = useSelector((state: IStoreState) => state.general.isLoading)
  const {
    consentId,
    clientSettings,
    publicScope,
  } = useSelector((state: IStoreState) => state.client)
  const { client_name, data_api_plan, verification_api_plan } = clientSettings
  const { status: abTestsStatus } = useAbTests()

  const dispatch = useDispatch()

  useEffect(() => {
    const initialiseApp = async () => {
      await dispatch(setIsLoading(true))
      const { sessionId } = getSessionId()
      if (
        !([PagePath.OVERVIEW, PagePath.OVERVIEW_V2, PagePath.CLIENT_ERROR] as string[]).includes(
          window.location.pathname,
        )
      ) {
        if (!sessionId) {
          throw AuthDialogSpaError.Create(ErrorCode.NoSessionId, 'No session id')
        }

        await Promise.all([
          dispatch(getProviders()),
          dispatch(getClient()),
          dispatch(getSession()),
          dispatch(getCountries()),
        ])

        dispatch(handleCountryAndProviders())

        dispatch(sendInitializedAnalytics())
      }
    }

    const initialise = async () => {
      try {
        await initialiseApp()

        setError(null)
      } catch (error) {
        if (
          error instanceof AuthDialogSpaError &&
          (error.code === ErrorCode.InternalServerError ||
            error.code === ErrorCode.UnauthorizedSession)
        ) {
          dispatch(sendFailedToInitialize(error.code, error.message))
        }

        const errorCode =
          error instanceof AuthDialogSpaError ? error.code : ErrorCode.UnexpectedError

        setError(errorMapper[errorCode])
      } finally {
        await dispatch(setIsLoading(false))
      }
    }

    initialise()
  }, [dispatch])

  useEffect(() => {
    if (consentId) dispatch(setConsentId(consentId))
  }, [consentId, dispatch])

  useEffect(() => {
    const title = `${client_name || 'TrueLayer'} - Connect your bank account`
    if (client_name) document.title = title
  }, [client_name])

  return {
    isLoading: isDataLoading && abTestsStatus !== AbTestsStatus.Loading,
    showAuthorisationBanner: showAuthorisationBanner({
      verificationApiPlan: verification_api_plan,
      dataApiPlan: data_api_plan,
      scopes: publicScope,
    }),
    error,
  }
}

export default useApp
