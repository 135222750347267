import { DataUseCategory } from '@store/IStoreState'
import { exhaustiveCheck, getConsoleBaseUrl } from '@utils/helpers'
import {
  AlphaNumericRegex,
  AwsS3UrlRegex,
  DefaultLogoUri,
  HexaRegex,
  UrlRegex,
} from '@utils/constants'
import snakeCase from 'lodash.snakecase'

export enum OverviewPage {
  Consent = 'CONSENT',
  ProviderSelection = 'PROVIDER_SELECTION',
}

export type RegulatoryInformation = {
  aisRegulated: boolean
  registrationNumber?: string
  privacyPolicyUrl?: string
  termsOfServiceUrl?: string
  registrationPageUrl?: string
}

export interface CustomizationPayload {
  clientName: string
  dataUse?: string
  dataUseCategory: DataUseCategory
  primaryColour: string
  secondaryColour: string
  tertiaryColour: string
  logo: Blob | string // can be a blob of an aws link
  cancellationUri: string
  isAgent: boolean
  clientAttributes?: {
    regulatoryInformation: { [key in 'eu' | 'uk']: RegulatoryInformation }
  }
}

export enum AuthDialogMessageType {
  Customization = 'AUTH_DIALOG_CUSTOMIZATION',
  Scale = 'AUTH_DIALOG_SCALE',
  Page = 'AUTH_DIALOG_PAGE',
}

type AuthDialogCustomizationMessage = {
  type: AuthDialogMessageType.Customization
  payload: CustomizationPayload
}

type AuthDialogScaleMessage = {
  type: AuthDialogMessageType.Scale
  payload: {
    scale: number
    height?: number
    width?: number
  }
}

type AuthDialogPageMessage = {
  type: AuthDialogMessageType.Page
  payload: {
    page: OverviewPage
  }
}

export type AuthDialogMessageEvent =
  | AuthDialogScaleMessage
  | AuthDialogPageMessage
  | AuthDialogCustomizationMessage

const isAuthDialogMessageType = (type: unknown): type is AuthDialogMessageType => {
  return typeof type === 'string' && Object.values<string>(AuthDialogMessageType).includes(type)
}

export const getPropertyFromUnknown = (value: unknown, property: string) => {
  return typeof value === 'object' && value && property in value
    ? (value as object & Record<string, unknown>)[property]
    : undefined
}

const isAuthDialogPageMessagePayload = (
  payload: unknown,
): payload is AuthDialogPageMessage['payload'] => {
  const page = getPropertyFromUnknown(payload, 'page')

  return typeof page === 'string' && Object.values<string>(OverviewPage).includes(page)
}

const isAuthDialogScaleMessagePayload = (
  payload: unknown,
): payload is AuthDialogScaleMessage['payload'] => {
  const scale = getPropertyFromUnknown(payload, 'scale')
  const width = getPropertyFromUnknown(payload, 'width')
  const height = getPropertyFromUnknown(payload, 'height')

  return (
    typeof scale === 'number' &&
    (!width || typeof width === 'number') &&
    (!height || typeof height === 'number')
  )
}

function isAwsS3UrlValid(logoUri: string | Blob) {
  if (logoUri instanceof Blob) {
    return true
  }

  const uri = logoUri

  return uri.length < 255 && (AwsS3UrlRegex.test(uri) || DefaultLogoUri.test(uri))
}

const isRegulatoryInformationValid = (
  regulatoryInformation: unknown,
): regulatoryInformation is RegulatoryInformation => {
  const aisRegulated = getPropertyFromUnknown(regulatoryInformation, 'aisRegulated')
  const registrationNumber = getPropertyFromUnknown(regulatoryInformation, 'registrationNumber')
  const privacyPolicyUrl = getPropertyFromUnknown(regulatoryInformation, 'privacyPolicyUrl')
  const termsOfServiceUrl = getPropertyFromUnknown(regulatoryInformation, 'termsOfServiceUrl')
  const registrationPageUrl = getPropertyFromUnknown(regulatoryInformation, 'registrationPageUrl')

  return (
    // `aisRegulated` validation
    typeof aisRegulated === 'boolean' &&
    // `registrationNumber` validation
    (registrationNumber === undefined || typeof registrationNumber === 'string') &&
    // `privacyPolicyUrl` validation
    (privacyPolicyUrl === undefined ||
      (typeof privacyPolicyUrl === 'string' && UrlRegex.test(privacyPolicyUrl))) &&
    // `termsOfServiceUrl` validation
    (termsOfServiceUrl === undefined ||
      (typeof termsOfServiceUrl === 'string' && UrlRegex.test(termsOfServiceUrl))) &&
    // `registrationPageUrl` validation
    (registrationPageUrl === undefined ||
      (typeof registrationPageUrl === 'string' && UrlRegex.test(registrationPageUrl)))
  )
}

const isClientAttributesValid = (
  clientAttributes: unknown,
): clientAttributes is AuthDialogCustomizationMessage['payload']['clientAttributes'] => {
  // `clientAttributes` is optional so we can return true if it's not present
  if (typeof clientAttributes === 'undefined') {
    return true
  }

  const regulatoryInformation = getPropertyFromUnknown(clientAttributes, 'regulatoryInformation')

  const ukRegulatoryInformation = getPropertyFromUnknown(regulatoryInformation, 'uk')
  const euRegulatoryInformation = getPropertyFromUnknown(regulatoryInformation, 'eu')

  return (
    isRegulatoryInformationValid(ukRegulatoryInformation) &&
    isRegulatoryInformationValid(euRegulatoryInformation)
  )
}

export const isAuthDialogCustomizationPayload = (
  payload: unknown,
): payload is AuthDialogCustomizationMessage['payload'] => {
  const clientName = getPropertyFromUnknown(payload, 'clientName')
  const dataUse = getPropertyFromUnknown(payload, 'dataUse')
  const dataUseCategory = getPropertyFromUnknown(payload, 'dataUseCategory')
  const primaryColour = getPropertyFromUnknown(payload, 'primaryColour')
  const secondaryColour = getPropertyFromUnknown(payload, 'secondaryColour')
  const tertiaryColour = getPropertyFromUnknown(payload, 'tertiaryColour')
  const logo = getPropertyFromUnknown(payload, 'logo')
  const cancellationUri = getPropertyFromUnknown(payload, 'cancellationUri')
  const isAgent = getPropertyFromUnknown(payload, 'isAgent')
  const clientAttributes = getPropertyFromUnknown(payload, 'clientAttributes')

  return (
    // `clientName` validation
    typeof clientName === 'string' &&
    AlphaNumericRegex.test(clientName) &&
    // `dataUse` validation
    (typeof dataUse === 'undefined' || (typeof dataUse === 'string' && dataUse.length < 255)) &&
    // `dataUseCategory` validation
    typeof dataUseCategory === 'string' &&
    // The `DataUseCategory` enum in the console app (which emits the events)
    // uses camel case so we need to convert it to snake case (which is what we use)
    Object.values<string>(DataUseCategory).includes(snakeCase(dataUseCategory)) &&
    // `primaryColour` validation
    typeof primaryColour === 'string' &&
    HexaRegex.test(primaryColour) &&
    // `secondaryColour` validation
    typeof secondaryColour === 'string' &&
    HexaRegex.test(secondaryColour) &&
    // `tertiaryColour` validation
    typeof tertiaryColour === 'string' &&
    HexaRegex.test(tertiaryColour) &&
    // `logoUri` validation
    (typeof logo === 'string' || logo instanceof Blob) &&
    isAwsS3UrlValid(logo) &&
    // `cancellationUri` validation
    typeof cancellationUri === 'string' &&
    UrlRegex.test(cancellationUri) &&
    // `isAgent` validation
    typeof isAgent === 'boolean' &&
    // `clientAttributes` validation
    isClientAttributesValid(clientAttributes)
  )
}

export const isAuthDialogMessageEvent = (
  // cast to unknown as the default `any` type
  // breaks the type safety
  event: MessageEvent<unknown>,
): event is MessageEvent<AuthDialogMessageEvent> => {
  if (!window.Cypress && event.origin !== getConsoleBaseUrl()) {
    return false
  }

  const type = getPropertyFromUnknown(event.data, 'type')

  if (!isAuthDialogMessageType(type)) {
    return false
  }

  const payload = getPropertyFromUnknown(event.data, 'payload')

  switch (type) {
    case AuthDialogMessageType.Customization: {
      const isValid = isAuthDialogCustomizationPayload(payload)

      return isValid
    }
    case AuthDialogMessageType.Scale: {
      const isValid = isAuthDialogScaleMessagePayload(payload)

      return isValid
    }
    case AuthDialogMessageType.Page: {
      const isValid = isAuthDialogPageMessagePayload(payload)

      return isValid
    }
    default:
      exhaustiveCheck(type)
  }
}
