import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { IAuthInputMethod, INotification, NotificationType } from '@models/IAuthInput'
import {
  addLoginInformation,
  setLoginStatus,
  setMfaData,
  setReturnUrl,
} from '@actions/login/loginActions'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '@hooks'
import {
  sendAuthInputsError,
  sendAuthInputsStepLoadedAnalytics,
  sendAuthInputsSubmitted,
  sendAuthInputsSuccess,
} from '@actions/analytics'
import { isFormValid } from '../utils'
import { isClientRegulated } from '@utils/client'
import { CheckmarkMiddleLogo, TLMiddleLogo } from '../../../components'
import { getAuthStartPayload } from '@utils/analytics'
import { authUriRequest } from '@middlewares/api'
import { getReturnUrlFromLoginResponse } from '@utils/providers'
import { LoginStatus } from '@models/loginStatus'

const useAuthInputs = () => {
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  const client = useSelector((state: IStoreState) => state.client)
  const clientName = client.clientSettings.client_name

  const { locationState } = usePageContext()
  const { notification } = locationState as { notification: INotification }
  const steps: IAuthInputMethod[] = provider.auth_inputs
  const [index, setIndex] = useState<number>(0)
  const [isLastStep, setIsLastStep] = useState<boolean>()
  const [step, setStep] = useState<IAuthInputMethod>()
  const [data, setData] = useState<object>({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [notifications, setNotifications] = useState<INotification[]>([])

  const { getState } = useStore()

  const dispatch = useDispatch()

  const { t } = useTranslation('v2_auth_inputs')

  // Update step
  useEffect(() => {
    if (steps && steps.length) {
      const newStep = steps[index]
      setStep(newStep)
      dispatch(sendAuthInputsStepLoadedAnalytics())
      if (index === steps.length - 1) {
        setIsLastStep(true)
      }
    }
  }, [steps, index, dispatch])

  // Check form readiness
  useEffect(() => {
    if (step) {
      setCanSubmit(isFormValid(step, data))
    }
  }, [data, step])

  const saveData = useCallback((key: string, value: string) => {
    const newData = { [key]: value }
    setData((data) => ({ ...data, ...newData }))
  }, [])

  const submitAuthInputs = async (event?: Event) => {
    if (event) {
      event.preventDefault()
    }

    if (isLastStep) {
      dispatch(sendAuthInputsSubmitted())

      // validate only if
      // - `client.consentId` is not empty
      //   (this is the case for the credentials sharing use case, where we can't fire a preconsent=true
      //   request as the consent has already been given at this point and API will return an error)
      if (!client.consentId) {
        const state: IStoreState = getState()
        const authStartPayload = getAuthStartPayload(state)
        setIsValidating(true)
        const response = await authUriRequest(authStartPayload, provider, data, true)

        try {
          const error = response.data?.error

          if (error?.code === 'need-mfa') {
            // "MFA error hack"
            // If the `/authuri` call ends up with a `need-mfa` as the error code and
            // a NON-empty `notifications` array, it means
            // that the data that user provided in the auth inputs aren't valid
            // in this scenario, we display the notification (=validation error)
            // to the user in the auth inputs page
            if (error?.details?.steps[0]?.notifications.length) {
              dispatch(sendAuthInputsError())
              setNotifications(error.details.steps[0].notifications)
            } else {
              // If the `/authuri` call ends up with a `need-mfa` as the error code
              // and the `notifications` array is empty, it means that the validation
              // has passed but there will be an MFA step needed after the consent has been granted.
              // We save the MFA data in the Redux store, this saves us the need to fire
              // a (duplicate) `/authuri` call on the consent page
              dispatch(sendAuthInputsSuccess())
              dispatch(addLoginInformation(data))
              dispatch(setMfaData(error?.details?.steps[0]))
            }
          } else {
            if (response.data.success) {
              // validation is OK and no MFA step needed, set the login status to `success`
              // and set the returnUrl to store. This will prevent us from firing a
              // (duplicate) `/authuri` call on the consent page
              dispatch(sendAuthInputsSuccess())
              dispatch(setLoginStatus(LoginStatus.Success))
              dispatch(setReturnUrl(getReturnUrlFromLoginResponse(provider, response)))
            }
            dispatch(addLoginInformation(data))
          }
        } catch (err) {
          dispatch(sendAuthInputsError())
        } finally {
          setIsValidating(false)
        }
      } else {
        dispatch(sendAuthInputsSuccess())
        dispatch(addLoginInformation(data))
      }
    } else {
      setIndex(index + 1)
    }
  }

  const getPageSubtitle = () => t('subtitle', { providerName: provider.display_name, clientName })

  const middleIcon = isClientRegulated({
    clientAttributes: client.clientAttributes,
    activeCountry: provider.country,
  }) ? (
    <CheckmarkMiddleLogo />
  ) : (
    <TLMiddleLogo />
  )

  const notificationFromValidation = notifications.length
    ? {
        ...notifications[0],
        type: NotificationType.ERROR,
      }
    : null

  return {
    getPageTitle: useLocalisedFormTitle(step),
    step,
    isLastStep,
    saveData,
    submitAuthInputs,
    canSubmit,
    provider,
    notification,
    notificationFromValidation,
    getPageSubtitle,
    middleIcon,
    client,
    isValidating,
  }
}

const useLocalisedFormTitle = (authInputMethod?: IAuthInputMethod): (() => string) => {
  // First: get actual localised title from form id
  // Second: get the localised generic auth inputs page title
  const { t } = useTranslation('formNames')
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)

  if (authInputMethod) {
    return () =>
      t(`${authInputMethod.id}_form_display_name`, { providerName: provider.display_name })
  }
  return () => t('default')
}

export default useAuthInputs
