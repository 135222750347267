/* eslint-disable no-useless-escape */
/* eslint-disable no-cond-assign */
import * as queryString from 'query-string'
import tinycolor from 'tinycolor2'
import { getEnv } from '@utils/env'
import { defaultDarkTextColour } from '../config/defaultColors'
import IProvider, { ProviderProtocolKeys } from '../models/IProvider'
import { getOS } from '@utils/platform'

/*
 * isEmpty returns true if a Object is empty
 */
export const isEmpty = (obj: object) => Object.keys(obj).length === 0

/* Get auth-analytics-api base url */
export const getAuthAnalyticsApiBaseUrl = () => `${getEnv().AUTH_ANALYTICS_URI}/api`

export const getConsoleBaseUrl = () => getEnv().CONSOLE_URI

export const getDialogBackendUrl = () => getEnv().AUTH_DIALOG_BACKEND_URI

export const getDialogBaseUrl = () => getEnv().AUTH_DIALOG_URI

export const getBuildNumber = () => process.env.REACT_APP_BUILD_NUMBER

export const getAppEnv = () => getEnv().APP_ENV

/*
 * isColourDark - check if the color is dark for the auth dialog standard
 */
export const isColourDark = (color: tinycolor.ColorInput) => {
  const rawColour = tinycolor(color)
  return color === defaultDarkTextColour || rawColour.getBrightness() < 180
}

export const getUriWithQueryParams = (uri: string, queryParams: string[]): string => {
  const concatenatedParams: string = queryParams.reduce((previousValue, currentValue) => {
    if (currentValue) {
      return previousValue ? `${previousValue}&${currentValue}` : currentValue
    }
    return previousValue
  }, '')

  if (!concatenatedParams) return uri
  return `${uri}?${concatenatedParams}`
}

export const getProviderId = (provider: IProvider, isNewNaming = true) => {
  let providerId = ''

  if (isNewNaming) {
    // for cs, connector_id will be the name of the bank i.e halifax, first-direct
    // for oauth, ob, connector_id will be the auth method with the name of the
    // bank -oauth-monzo/ob-hsbc
    // The provider_id will be country-authmethod-name
    providerId =
      provider.provider_id ||
      (provider.auth_method === 'cs'
        ? `${provider.country}-${provider.auth_method}-${provider.connector_id}`
        : `${provider.country}-${provider.connector_id}`)
  } else {
    providerId = provider.connector_id
  }

  return providerId
}

/*
 * Check if a provider is an OpenBanking provider
 */
export const isOpenBankingProvider = (provider: IProvider) => {
  if (provider.auth_method === ProviderProtocolKeys.OPEN_BANKING) {
    return true
  }
  return false
}

/*
 * Check if a provider is an OAuth provider
 */
export const isOAuthProvider = (provider: IProvider) => {
  if (provider.auth_method === ProviderProtocolKeys.OAUTH) {
    return true
  }
  return false
}

/*
 * Return the screen size
 */

export function getScreenSize() {
  let screenSize = ''
  if (window.screen.width) {
    const width = window.screen.width ? window.screen.width : ''
    const height = window.screen.height ? window.screen.height : ''
    screenSize = `${width}x${height}`
    return screenSize
  }

  return screenSize
}
/*
 * Return the browser version
 */
export function getBrowserDetails() {
  // browser
  const nAgt = navigator.userAgent
  let browser = navigator.appName
  let version = `${parseFloat(navigator.appVersion)}`
  let majorVersion = parseInt(navigator.appVersion, 10)
  let nameOffset
  let verOffset
  let ix

  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browser = 'Opera'
    version = nAgt.substring(verOffset + 6)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
    browser = 'Opera'
    version = nAgt.substring(verOffset + 4)
  } else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
    browser = 'Microsoft Edge'
    version = nAgt.substring(verOffset + 5)
  } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browser = 'Microsoft Internet Explorer'
    version = nAgt.substring(verOffset + 5)
  } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browser = 'Chrome'
    version = nAgt.substring(verOffset + 7)
  } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browser = 'Safari'
    version = nAgt.substring(verOffset + 7)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browser = 'Firefox'
    version = nAgt.substring(verOffset + 8)
  } else if (nAgt.indexOf('Trident/') !== -1) {
    browser = 'Microsoft Internet Explorer'
    version = nAgt.substring(nAgt.indexOf('rv:') + 3)
  } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browser = nAgt.substring(nameOffset, verOffset)
    version = nAgt.substring(verOffset + 1)
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName
    }
  }
  // trim the version string
  if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix)
  if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix)
  if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix)

  majorVersion = parseInt(`${version}`, 10)
  if (Number.isNaN(majorVersion)) {
    version = `${parseFloat(navigator.appVersion)}`
    majorVersion = parseInt(navigator.appVersion, 10)
  }

  return `${browser} ${majorVersion} (${version})`
}

/*
 * Return the OS details
 */

export function getOSDetails() {
  switch (getOS().name) {
    case 'macOS':
      return 'Mac OS'
    case 'iOS':
      return 'iOS'
    case 'Windows':
      return 'Windows'
    case 'Android':
      return 'Android'
    case 'Linux':
      return 'Linux'
    default:
      // In case of unsupported platforms (e.g. Nokia N9)
      return getOS().name || 'Not detected'
  }
}

/*
 * Get Device Type
 */

export function getDeviceType() {
  const deviceType = isMobile() ? 'Mobile' : 'Desktop'
  return deviceType
}

/*
 * Return providerType for mixpanel
 */

export function getProviderType(provider: IProvider) {
  if (!provider || !provider.connector_id) {
    return undefined
  }

  if (isOpenBankingProvider(provider)) {
    return 'OB_PROVIDERS'
  }
  if (isOAuthProvider(provider)) {
    return 'CHALL_PROVIDERS'
  }
  return 'CS_PROVIDERS'
}

/*
 *
 */

export function isMobile() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor) //eslint-disable-line
  return check
}

/*
 * Get the System Languages
 */

export function getSystemLanguages() {
  if (window.navigator) {
    if (window.navigator.languages) {
      return window.navigator.languages[0] || ''
    }
  }
  return ''
}

// In some cases the sessionId is null like on the Overview page
export const getSessionId = (): { sessionId: string | null } => {
  const value: string = queryString.parse(window.location.search).session_id as string
  return { sessionId: value }
}

// A simple utility method to return a list of distinct array based on a predicate
// (it's up to the consumer to define the equality)
export function distinctArray<T>(
  array: T[],
  uniquenessFn: (source: T, compare: T) => boolean,
): T[] {
  const unique: T[] = []
  array.forEach((value) => {
    const element = unique.find((value1) => uniquenessFn(value1, value))
    if (!element) {
      unique.push(value)
    }
  })
  return unique
}

export function isInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return false
  }
}

// String array intersection
export const intersection = (arr1: string[], arr2: string[]) => arr1.filter((x) => arr2.includes(x))

export const fireAndForget = (promise: Promise<unknown>) => promise

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

// used in switch statement to assert that all cases are handled
// (more info e. g. on https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript)
export function exhaustiveCheck(value: never): never {
  throw new Error(`Unhandled value: ${value}`)
}
